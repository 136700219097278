'use strict';

import {FieldFactory} from '../../factory/FieldFactory';
import FieldUnit from '../../factory/FieldUnit';
import FieldView from './Field';
import CoupleField from './subfields/Couple';
import { PortalView } from '../PortalView';
import RecordUtils from '../../utils/RecordUtils';
import * as _ from 'lodash';
const { Group, Field } = require('@sai/configuration');

class GroupView extends PortalView {
    private fields : {[fieldId: string] : FieldView};
    private fieldsArr : Array<FieldView>;
    private title : any;
    private minLeft : number;
    private maxLeft : number;
    private domainContext : any;
    private displayMode: string;
    private groupConfig: typeof Group;
    private enabled: boolean;
    private hiddenFields: Array<string>
    private fileUploadCallback: any;

    constructor(options? : any) {
        super(options);
        this.fields = {};
        this.fieldsArr = [];
        this.enabled = options.enabled !== undefined ? options.enabled : true;
        this.groupConfig = options.groupConfig;
        this.domainContext = options.domainContext;
        this.fields = {};
        this.template = require('../../templates/fields/Group.ejs');
        this.displayMode = options.displayMode;
        this.hiddenFields = options.hiddenFields;
        this.fileUploadCallback = options.fileUploadCallback;
        this.setFields(this.groupConfig.getFields());
    }

    render() : any {
        var currentTitle = this.groupConfig.getLabel() ? this.groupConfig.getLabel() : 'None';
        this.$el.html(this.template({
            title: currentTitle,
            dominantColor: this.options.dominantColor,
            titleHeight: FieldUnit['tablet'].height - 10,
        }));

        this.title = this.$el.find('.groupTitle');

        this.$el.addClass('group');
        this.$el.addClass('mt-2');
        this.$el.addClass(this.displayMode);
        this.$el.attr('data-id', this.groupConfig.getId());

        this.renderFields();
        this.addGroupHelp();
    }

    setFields(fieldsConfig) {
        this.clearCurrentFields();

        for (var key in fieldsConfig) {
            var newField: typeof Field = fieldsConfig[key];
            var newView: FieldView = FieldFactory.buildField(newField, this.displayMode, this.domainContext, !this.hiddenFields.includes(newField.getConfig().datafieldId), this.fileUploadCallback);
            newView.setEnabled(newView.isEnabled() && this.enabled);
            if (newView) {
                this.fields[newView.getState().getId()] = newView;
                this.fieldsArr.push(newView);
            }
            if(newField.isCouple()) {
                //We need to set its associated field
                let associatedFieldConfig = newField.getLinkedCouple().getLabelField();
                if(associatedFieldConfig.getId() === newField.getId()) {
                    associatedFieldConfig = newField.getLinkedCouple().getKeyField();
                }
                //The first field won't have his friend, the second one will
                if(this.fields[associatedFieldConfig.getId()] !== undefined) {
                    (newView as CoupleField).setAssociatedField(this.fields[associatedFieldConfig.getId()] as CoupleField);
                    (this.fields[associatedFieldConfig.getId()] as CoupleField).setAssociatedField(newView as CoupleField);
                }
            }
        }
    }

    clearCurrentFields() {
        this.fields = {};

    }

    /**
     * Builds the fields DOM entries within the fieldsContainer
     * entry and position them based on their row property in both phone
     * and tablet mode.
    */
    renderFields() {
        var fieldContainer = this.$el.find('.fieldsContainer');
        fieldContainer.empty();
        let currentTabletRow = undefined;
        let currentPhoneRow = undefined;
        let currentWidthTabletValue = 0;
        let currentWidthPhoneValue = 0;
        /*
         * The row positionning is based on row property definition in both phone and tablet mode
         * However, as we want to display the rows size changes based on natural css, we can't have
         * a différent order between fields, they can only have different size which would imply
         * different rows.
         *
         * Thus, we initially use the tablet ordering to define the fields order before rendering
         * them using the bootstrap grid properties.
        */

        //First keeping the original fields order to garanty same row order
        let count = 0;
        let naturalFields = _.map(this.fields, (obj, fieldId) => {
            return { field: obj, index: count++};
        });

        //Sorting by row and field order
        let sorted = _.sortBy(naturalFields, (obj) => {
            let row = parseInt(obj.field.getConfig().getPosition('tablet').row);
            return row * 1000 + obj.index;
        });

        let hasVisibleFields = false;

        //Rendering fields
        for (var key in sorted) {
            var currentField = sorted[key].field;

            let clearfix = undefined;
            let currentFieldVisible = currentField.isVisible() && !this.hiddenFields.includes(currentField.getConfig().getDatafieldId());
            let labelOnly: boolean = false;
            hasVisibleFields = hasVisibleFields || currentFieldVisible;

            /*
             * In order to force the display to go back to a new line, we need to generate
             * a fake invisible entry that will fill the line to the end so that the next field
             * starts on a new row. Based on the current display (phone or tablet) the size and
             * necessity of this element might vary
            */

            //Computing filler for tablet mode size
            let tabletRow = parseInt(currentField.getConfig().getPosition('tablet').row);
            let differentTabletRow: boolean = currentTabletRow !== undefined && tabletRow !== currentTabletRow;
            if (differentTabletRow && currentWidthTabletValue > 0 && currentFieldVisible) {
                clearfix = $('<div></div>').appendTo(fieldContainer);
                clearfix.addClass(['row-clearfix', 'row-clearfix-min-md', 'col-md-' + (12 - currentWidthTabletValue)]);
                currentWidthTabletValue = 0;
            }
            let tabletWidth = RecordUtils.getGridPosFromValue(currentField.getConfig().getPosition('tablet').width);
            if (tabletWidth < 0 && currentFieldVisible) {
                //We only want to display the label, we thus have to recompute the value
                let widthValue = currentField.getConfig().getPosition('tablet').width;
                let newWidth = (100 + parseInt(widthValue.slice(0,-1))) + '%';
                tabletWidth = RecordUtils.getGridPosFromValue(newWidth);
                labelOnly = true;
            }

            //Computing filler for phone mode size
            let phoneRow = parseInt(currentField.getConfig().getPosition('phone').row);
            let differentPhoneRow: boolean = currentPhoneRow !== undefined && phoneRow !== currentPhoneRow;
            if (differentPhoneRow && currentWidthPhoneValue > 0 && currentFieldVisible) {
                clearfix = clearfix || $('<div></div>').appendTo(fieldContainer);
                clearfix.addClass(['row-clearfix', 'row-clearfix-max-sm', 'col-sm-' + (12 - currentWidthPhoneValue)]);
                currentWidthPhoneValue = 0;
            }
            let phoneWidth = RecordUtils.getGridPosFromValue(currentField.getConfig().getPosition('phone').width);
            if (labelOnly) {
                //Recomputing the value in phone mode as well
                let widthValue = currentField.getConfig().getPosition('phone').width;
                let newWidth = (100 + parseInt(widthValue.slice(0, -1))) + '%';
                phoneWidth = RecordUtils.getGridPosFromValue(newWidth);
            }

            //We can now append the field, it'll be at the right position
            var newFieldEl = $('<div>').appendTo(fieldContainer);
            currentField.setElement(newFieldEl);
            currentField.render();
            if(labelOnly) {
                newFieldEl.find('label').addClass('label-only');
                newFieldEl.find('input').hide();
            }

            //Based on its size in differents modes we append the bootstrap column property with the right width
            newFieldEl.addClass('col-md-' + tabletWidth);
            newFieldEl.addClass('col-sm-' + phoneWidth);

            if (currentFieldVisible) {
                currentTabletRow = tabletRow;
                currentPhoneRow = phoneRow;

                /*
                * Computing the new position of each mode after field insertion.
                * Bootstrap uses a 12 columns grid mode which implies that if the
                * new positions is greater that this, then the display will automatically
                * wrap the field to a new position, thus it means that the field is alone
                * on the row for now.
                */
                currentWidthTabletValue = currentWidthTabletValue + tabletWidth;
                if (currentWidthTabletValue > 12) {
                    currentWidthTabletValue = tabletWidth;
                } else if (currentWidthTabletValue == 12) {
                    currentWidthTabletValue = 0;
                }
                currentWidthPhoneValue = currentWidthPhoneValue + phoneWidth;
                if (currentWidthPhoneValue > 12) {
                    currentWidthPhoneValue = phoneWidth;
                } else if (currentWidthPhoneValue == 12) {
                    currentWidthPhoneValue = 0;
                }
            }
        }
        this.moveFieldsToPositions();

        //Calling post-DOM operations on fields now that they are rendered
        //This will allow creating of special selection-boxes, initialisation
        //of date pickers, etc...
        for (var key2 in this.fields) {
            this.fields[key2].onDOMUpdated(true);
        }

        if(!hasVisibleFields) {
            this.$el.hide();
        }
    }

    moveFieldsToPositions() {
        //Nothing to do here, fields positions are not set in absolute position
        //However this could be overrided in some case.
    }

    public getFieldMinLeft(){
        return this.minLeft;
    }

    public getFieldMaxLeft() {
        return this.maxLeft;
    }

    getFieldsValues() {
        var output: any = {};
        for (var key in this.fields) {
            let curField = this.fields[key];
            output[key] = {
                value: curField.getValue(),
                initialValue: curField.getInitialValue(),
                lastValue: curField.getLastValue(),
                lastNonEmptyValue: curField.getLastNonEmptyValue(),
                humanValue: curField.getHumanValue(),
                visible: curField.isVisible(),
                enabled: curField.isEnabled()
            };
            if (curField['getPendingDocuments']) {
                var docs = curField['getPendingDocuments']();
                if (docs) {
                    output[key].notes = docs;
                }
            }
        }
        return output;
    }

    getFields(filter: any): Array<FieldView> {
        return _.filter(this.fields, filter);
    }

    getOrderedFields(): Array<FieldView> {
        return this.fieldsArr;
    }

    getField(fieldId: string): FieldView {
        return this.fields[fieldId];
    }

    setDominantColor(color: string){
        if(this.title){
            this.title.css('border-bottom-color', color);
            this.title.children('.groupTitleText').css('color', color);
        }

        for (var key in this.fields) {
            this.fields[key].setDominantColor(color);
        }
    }

    public getConfig(): typeof Group {
        return this.groupConfig;
    }

    public getDisplayMode(): string {
        return this.displayMode;
    }

    protected addGroupHelp(): void {
        let help = this.getConfig().getConfig().help;
        if(help !== undefined && help !== '') {
            let helpIcon = this.$el.find('>.help-icon');
            helpIcon.show();
            helpIcon.attr('title',help);
            //this.$el.css('padding-right','40px');
            (helpIcon as any).tooltip();
        }
    }

    public getId(): string {
        return this.getConfig().getId();
    }
}

export default GroupView;
